import React, { useState } from 'react';
import { TextField, Button } from '@mui/material';
import { Scissors } from 'lucide-react';

const Designers = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [instagram, setInstagram] = useState('');
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log({ name, email, instagram });
    setSubmitted(true);
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-purple-200 to-indigo-300 flex items-center justify-center p-4">
      <div className="bg-white rounded-lg shadow-xl p-8 max-w-md w-full">
        <div className="flex items-center justify-center mb-6">
          <Scissors className="h-12 w-12 text-indigo-700" />
          <h1 className="text-3xl font-bold ml-2 text-gray-900">WearWonder</h1>
        </div>
        <h2 className="text-2xl font-semibold text-gray-800 mb-6 text-center">Designer Signup</h2>

        {submitted ? (
          <div className="text-center">
            <h3 className="text-xl font-semibold text-teal-600 mb-4">Thank You for Signing Up!</h3>
            <p className="text-gray-800">
              Your application is on the waitlist. We will reach out to you soon.
            </p>
          </div>
        ) : (
          <form onSubmit={handleSubmit} className="space-y-4">
            <TextField
              id="name"
              label="Name"
              variant="outlined"
              fullWidth
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
              InputLabelProps={{ style: { color: '#4b5563' } }}
              inputProps={{ style: { color: '#1f2937' } }}
              sx={{ marginBottom: 2 }}
            />
            <TextField
              id="email"
              label="Email"
              type="email"
              variant="outlined"
              fullWidth
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              InputLabelProps={{ style: { color: '#4b5563' } }}
              inputProps={{ style: { color: '#1f2937' } }}
              sx={{ marginBottom: 2 }}
            />
            <TextField
              id="instagram"
              label="Instagram Handle"
              variant="outlined"
              fullWidth
              value={instagram}
              onChange={(e) => setInstagram(e.target.value)}
              required
              InputLabelProps={{ style: { color: '#4b5563' } }}
              inputProps={{ style: { color: '#1f2937' } }}
              sx={{ marginBottom: 2 }}
            />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              sx={{ backgroundColor: '#6d28d9', '&:hover': { backgroundColor: '#4c1d95' } }}
            >
              Sign Up
            </Button>
          </form>
        )}

        <p className="mt-4 text-sm text-gray-600 text-center">
          Join our community of innovative designers and bring your fashion ideas to life!
        </p>
      </div>
    </div>
  );
};

export default Designers;
