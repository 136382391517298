import React from 'react';

const PrivacyPolicyPage = () => {
  return (
    <div className="max-w-4xl mx-auto p-8 bg-gray-100 min-h-screen">
      <h1 className="text-4xl font-extrabold mb-8 text-center text-gray-900">Privacy Policy</h1>
      <div className="bg-white shadow-lg rounded-lg p-8">
        <p className="mb-6 text-gray-700">At WearWonder, we are committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our services. Please read this policy carefully to understand our views and practices regarding your personal data and how we will treat it.</p>

        <h2 className="text-2xl font-semibold mb-4 text-gray-800">1. Information We Collect</h2>
        <p className="mb-6 text-gray-700">We collect various types of information in connection with your use of our services, including:</p>
        <ul className="list-disc list-inside mb-4 text-gray-700">
          <li><strong>Personal Information:</strong> Information you provide directly to us, such as your name, email address, and other contact details when you create an account or interact with our platform.</li>
          <li><strong>Usage Data:</strong> Information about your interactions with our services, including your IP address, browser type, and usage patterns.</li>
          <li><strong>Device Information:</strong> Information about the device you use to access our services, including device type, operating system, and unique device identifiers.</li>
        </ul>

        <h2 className="text-2xl font-semibold mb-4 text-gray-800">2. How We Use Your Information</h2>
        <p className="mb-6 text-gray-700">We use the information we collect for the following purposes:</p>
        <ul className="list-disc list-inside mb-4 text-gray-700">
          <li><strong>To Provide and Improve Services:</strong> To deliver and enhance our services, including managing your account and personalizing your experience.</li>
          <li><strong>To Communicate with You:</strong> To send you updates, notifications, and promotional materials related to our services.</li>
          <li><strong>To Analyze and Understand Usage:</strong> To analyze trends, track user interactions, and improve our platform.</li>
        </ul>

        <h2 className="text-2xl font-semibold mb-4 text-gray-800">3. Sharing of Information</h2>
        <p className="mb-6 text-gray-700">We do not share your personal information with third parties except in the following circumstances:</p>
        <ul className="list-disc list-inside mb-4 text-gray-700">
          <li><strong>With Service Providers:</strong> We may share your information with third-party vendors who perform services on our behalf, such as payment processors or email service providers.</li>
          <li><strong>For Legal Reasons:</strong> We may disclose your information if required to do so by law or in response to valid requests by public authorities.</li>
          <li><strong>Business Transfers:</strong> In the event of a merger, acquisition, or sale of assets, your information may be transferred as part of the transaction.</li>
        </ul>

        <h2 className="text-2xl font-semibold mb-4 text-gray-800">4. Data Security</h2>
        <p className="mb-6 text-gray-700">We implement appropriate technical and organizational measures to safeguard your personal information against unauthorized access, disclosure, or destruction. However, please be aware that no security measures are completely infallible.</p>

        <h2 className="text-2xl font-semibold mb-4 text-gray-800">5. Your Choices and Rights</h2>
        <p className="mb-6 text-gray-700">You have certain rights regarding your personal information, including:</p>
        <ul className="list-disc list-inside mb-4 text-gray-700">
          <li><strong>Access and Correction:</strong> You can access and update your personal information by logging into your account.</li>
          <li><strong>Opt-Out:</strong> You can opt-out of receiving promotional emails by following the unsubscribe instructions in those emails.</li>
          <li><strong>Data Deletion:</strong> You can request the deletion of your personal information, subject to certain exceptions required by law.</li>
        </ul>

        <h2 className="text-2xl font-semibold mb-4 text-gray-800">6. Changes to This Privacy Policy</h2>
        <p className="mb-6 text-gray-700">We may update this Privacy Policy from time to time. We will notify you of any changes by posting the updated policy on our website. Your continued use of our services after such changes constitutes your acceptance of the revised policy.</p>

        <h2 className="text-2xl font-semibold mb-4 text-gray-800">7. Contact Us</h2>
        <p className="text-gray-700">If you have any questions about this Privacy Policy or our data practices, please contact us at <a href="mailto:support@wearwonder.com" className="text-indigo-600 hover:underline">support@wearwonder.com</a>.</p>
      </div>
    </div>
  );
};

export default PrivacyPolicyPage;
