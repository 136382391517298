import React from 'react';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <footer className="bg-purple-800 text-white py-8">
      <div className="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8">
          <div className="p-4">
            <h3 className="text-xl font-semibold mb-4">WearWonder</h3>
            <ul className="space-y-3">
              <li><Link to="/about" className="text-white hover:opacity-75 transition-opacity">About Us</Link></li>
              <li><Link to="/contact" className="text-white hover:opacity-75 transition-opacity">Contact</Link></li>
            </ul>
          </div>
          <div className="p-4">
            <h3 className="text-xl font-semibold mb-4">Resources</h3>
            <ul className="space-y-3">
              <li><Link to="/faq" className="text-white hover:opacity-75 transition-opacity">FAQ</Link></li>
              <li><Link to="/support" className="text-white hover:opacity-75 transition-opacity">Support</Link></li>
            </ul>
          </div>
          <div className="p-4">
            <h3 className="text-xl font-semibold mb-4">Legal</h3>
            <ul className="space-y-3">
              <li><Link to="/terms" className="text-white hover:opacity-75 transition-opacity">Terms of Service</Link></li>
              <li><Link to="/privacy" className="text-white hover:opacity-75 transition-opacity">Privacy Policy</Link></li>
            </ul>
          </div>
        </div>
        <div className="mt-8 border-t border-white border-opacity-25 pt-4 text-center text-sm text-white text-opacity-80">
          <p>&copy; 2024 WearWonder.com. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
