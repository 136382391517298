import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import LandingPage from './pages/HomePage';
import AboutPage from './pages/AboutPage';
import HowItWorksPage from './pages/HowItWorksPage';
import Designers from './pages/Designers';
import ContactPage from './pages/ContactPage';
import FAQPage from './pages/FAQPage';
import SupportPage from './pages/SupportPage';
import TermsOfServicePage from './pages/TermsPage';
import PrivacyPolicyPage from './pages/PrivacyPage';

function App() {
  return (
    <Router>
      <div className="App flex flex-col min-h-screen bg-gradient-to-b from-purple-100 to-pink-100">
        <Header />
        <main className="flex-grow">
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/how-it-works" element={<HowItWorksPage />} />
            <Route path="/designers" element={<Designers />} />
            <Route path="/contact" element={<ContactPage />} />
            <Route path="/faq" element={<FAQPage />} />
            <Route path="/support" element={<SupportPage />} />
            <Route path="/terms" element={<TermsOfServicePage />} />
            <Route path="/privacy" element={<PrivacyPolicyPage />} />
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
}

export default App;