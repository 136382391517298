import React from 'react';

const SupportPage = () => {
  return (
    <div className="max-w-3xl mx-auto p-6 bg-gray-100 min-h-screen">
      <h1 className="text-4xl font-extrabold mb-8 text-center text-gray-900">Support Center</h1>
      <div className="bg-white shadow-lg rounded-lg p-8">
        <h2 className="text-3xl font-semibold mb-6 text-gray-800">How Can We Assist You?</h2>
        <p className="mb-6 text-gray-700">Our support team is here to help with any questions or issues you may have. Please find our contact details and support resources below.</p>
        <ul className="list-disc list-inside mb-6 text-gray-700">
          <li>Email: <a href="mailto:support@wearwonder.com" className="text-indigo-600 hover:underline">support@wearwonder.com</a></li>
          <li>Phone: <a href="tel:+15551234567" className="text-indigo-600 hover:underline">+91-9711676564</a></li>
          <li>Hours: Monday - Friday, 9am - 5pm EST</li>
        </ul>
        <p className="text-gray-700">For quicker answers, please visit our <a href="/faq" className="text-indigo-600 hover:underline">FAQ page</a> before reaching out to us.</p>
      </div>
    </div>
  );
};

export default SupportPage;
