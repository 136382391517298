import React from 'react';

function AboutPage() {
  return (
    <div className="max-w-3xl mx-auto p-6">
      <h1 className="text-3xl font-bold text-gray-800 mb-6">About WearWonder</h1>
      
      <p className="text-base leading-relaxed text-gray-700 mb-6">
        WearWonder.com is revolutionizing the fashion industry by empowering designers with cutting-edge AI technology and on-demand manufacturing solutions. Our platform bridges the gap between creativity and production, allowing fashion designers to bring their visions to life more efficiently and profitably than ever before.
      </p>

      <h2 className="text-2xl font-semibold text-gray-800 mt-8 mb-4">Our Mission</h2>
      <p className="text-base leading-relaxed text-gray-700 mb-6">
        Our mission is to democratize fashion design and production. We believe that every designer, regardless of their resources or connections, should have the opportunity to showcase their talent and bring their unique creations to market. By leveraging AI and a vast network of manufacturers, we're making this vision a reality.
      </p>

      <h2 className="text-2xl font-semibold text-gray-800 mt-8 mb-4">What We Do</h2>
      <p className="text-base leading-relaxed text-gray-700 mb-6">
        WearWonder.com provides an end-to-end solution for fashion designers:
      </p>
      <ul className="list-disc list-inside text-base leading-relaxed text-gray-700 mb-6">
        <li>AI-powered tools to transform sketches into production-ready catalogs and tech packs</li>
        <li>Access to a network of over 1000 tailors and manufacturers for on-demand production</li>
        <li>A platform to sell unique creations directly to consumers</li>
      </ul>

     

      <h2 className="text-2xl font-semibold text-gray-800 mt-8 mb-4">Join Us</h2>
      <p className="text-base leading-relaxed text-gray-700 mb-6">
        Whether you're a fashion designer looking to bring your creations to life or an enthusiast excited about the future of fashion, we invite you to join the WearWonder community. Together, we're shaping the future of fashion design and production.
      </p>
    </div>
  );
}

export default AboutPage;
