import React from 'react';

const FAQPage = () => {
  const faqs = [
    {
      question: "How does WearWonder work?",
      answer: (
        <>
          <p className="mb-4">
            WearWonder is an innovative platform that leverages advanced technology to streamline the fashion design and production process. Here's how it works:
          </p>
          <ol className="list-decimal list-inside mb-4 space-y-2">
            <li>
              <strong>Design Submission:</strong> Designers submit their fashion sketches or concepts through our intuitive online interface. Our platform supports various file formats and provides tools to enhance your submissions.
            </li>
            <li>
              <strong>AI-Powered Analysis:</strong> WearWonder's AI analyzes your designs to generate detailed tech packs, which include specifications for materials, dimensions, and production methods. This step ensures that your designs are production-ready.
            </li>
            <li>
              <strong>Manufacturing:</strong> Once the tech packs are finalized, we connect you with our network of manufacturers. You can choose from a range of manufacturing options tailored to your design's requirements.
            </li>
            <li>
              <strong>Sales and Distribution:</strong> After production, your designs are listed on our platform where consumers can view and purchase them. We handle order fulfillment, allowing you to focus on creating new designs.
            </li>
          </ol>
          <p>
            Our goal is to simplify the fashion design process and empower designers to bring their visions to life efficiently and effectively.
          </p>
        </>
      )
    },
    {
      question: "Can I sell my designs on WearWonder?",
      answer: (
        <>
          <p className="mb-4">
            Absolutely! WearWonder is designed to provide designers with a comprehensive platform to showcase and sell their creations. Here's how you can get started:
          </p>
          <ol className="list-decimal list-inside mb-4 space-y-2">
            <li>
              <strong>Create an Account:</strong> Sign up on WearWonder and create your designer profile. This will allow you to upload your designs and manage your listings.
            </li>
            <li>
              <strong>Upload Your Designs:</strong> Submit your fashion designs through our user-friendly interface. You can provide detailed descriptions, materials used, and any other relevant information to attract potential buyers.
            </li>
            <li>
              <strong>Set Your Prices:</strong> Determine the pricing for your designs. You have full control over the pricing and can make adjustments as needed.
            </li>
            <li>
              <strong>Promote Your Creations:</strong> Utilize our platform’s marketing tools to promote your designs. We offer features to help you reach a wider audience and increase visibility.
            </li>
            <li>
              <strong>Manage Sales:</strong> Track sales, manage orders, and receive payments directly through your WearWonder account. Our secure payment processing system ensures smooth transactions.
            </li>
          </ol>
          <p>
            Join our community of designers and start turning your fashion ideas into a successful venture today!
          </p>
        </>
      )
    },
    {
      question: "What types of manufacturing options are available?",
      answer: (
        <>
          <p className="mb-4">
            At WearWonder, we simplify the entire manufacturing process for you. As a designer, you don’t need to worry about the intricacies of production—our platform handles everything from start to finish. Here’s how it works:
          </p>
          <ul className="list-disc list-inside mb-4 space-y-2">
            <li>
              <strong>Seamless Integration:</strong> Once you upload your designs, WearWonder takes care of the entire manufacturing process. Whether it's a single custom piece or a large-scale production run, we manage it all behind the scenes.
            </li>
            <li>
              <strong>On-Demand Production:</strong> We offer on-demand manufacturing, which means each design is produced only when an order is placed. This model eliminates the need for inventory management and reduces waste.
            </li>
            <li>
              <strong>Scalable Solutions:</strong> Whether you have a small collection or a high-volume launch, our network of manufacturers can handle orders of any size. We scale production to meet demand, ensuring timely delivery of your designs.
            </li>
            <li>
              <strong>Direct Sales Links:</strong> After production, you receive a unique e-commerce purchase link for each design. This link allows customers to view and purchase your products directly from WearWonder, streamlining the sales process.
            </li>
          </ul>
          <p>
            Our goal is to empower you to focus on your creativity while we handle the complexities of manufacturing and sales. With WearWonder, you can confidently showcase and sell your designs without the hassle of production logistics.
          </p>
        </>
      )
    },
    {
      question: "How can I contact WearWonder support?",
      answer: (
        <>
          <p className="mb-4">
            If you need assistance or have any questions, our support team is here to help. You can reach out to us through the following methods:
          </p>
          <ul className="list-disc list-inside mb-4 space-y-2">
            <li>
              <strong>Email:</strong> Send us an email at <a href="mailto:support@wearwonder.com" className="text-indigo-600 hover:underline">support@wearwonder.com</a>. We aim to respond to all inquiries within 24-48 hours.
            </li>
            <li>
              <strong>Phone:</strong> Call us at +91-9711676564 during our business hours: Monday - Friday, 9 AM - 5 PM EST.
            </li>
            <li>
              <strong>Live Chat:</strong> Use the live chat feature on our website for real-time assistance. Our team is available during business hours to help you with any issues.
            </li>
            <li>
              <strong>Contact Form:</strong> Fill out the contact form on our website and provide details about your inquiry. We will get back to you as soon as possible.
            </li>
          </ul>
          <p>
            We are committed to providing excellent support and ensuring that your experience with WearWonder is smooth and enjoyable.
          </p>
        </>
      )
    },
  ];

  return (
    <div className="max-w-4xl mx-auto p-8 bg-gray-100 min-h-screen">
      <h1 className="text-4xl font-extrabold mb-8 text-center text-gray-900">Frequently Asked Questions</h1>
      <div className="space-y-8">
        {faqs.map((faq, index) => (
          <div key={index} className="bg-white shadow-lg rounded-lg p-8">
            <h2 className="text-2xl font-semibold mb-4 text-gray-800">{faq.question}</h2>
            <div className="text-gray-700">
              {faq.answer}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQPage;
