import React from 'react';

function HowItWorksPage() {
  return (
    <div className="max-w-4xl mx-auto p-6">
      <h1 className="text-3xl font-bold text-gray-800 mb-6">How WearWonder Works</h1>
      
      <p className="text-base leading-relaxed text-gray-700 mb-6">
        WearWonder.com revolutionizes the fashion design process, empowering designers to bring their creations to life with ease. Here's how our platform works:
      </p>

      <div className="space-y-8">
        {[
          { step: 1, title: 'Upload Your Sketches', description: 'Begin by uploading your fashion sketches to our platform. We accept various file formats, making it easy to transfer your creative ideas into our system.' },
          { step: 2, title: 'AI-Powered Transformation', description: 'Our advanced AI technology transforms your sketches into high-quality, market-ready images. It also generates detailed tech packs, providing all the necessary information for manufacturing.' },
          { step: 3, title: 'Review and Refine', description: 'Review the AI-generated images and tech packs. Make any necessary adjustments or refinements to ensure your design is perfect and ready for production.' },
          { step: 4, title: 'Publish and Grow', description: 'Publish your designs to WearWonder.com, where they become available for purchase across the globe. Use our dashboard to track sales and gather customer feedback to refine your designs and grow your fashion brand.' },
        ].map(({ step, title, description }) => (
          <div key={step} className="flex items-start space-x-4 mb-8">
            <div className="flex-shrink-0 bg-purple-600 text-white rounded-full w-10 h-10 flex items-center justify-center text-lg font-bold">
              {step}
            </div>
            <div className="flex-1">
              <h2 className="text-xl font-semibold text-gray-800 mb-2">{title}</h2>
              <p className="text-base leading-relaxed text-gray-700">{description}</p>
            </div>
          </div>
        ))}

        <p className="text-base leading-relaxed text-gray-700 mb-6">
          With WearWonder.com, you have full control over your design process, from concept to customer. Our platform eliminates traditional barriers, allowing you to focus on what you do best - creating amazing fashion designs.
        </p>

        <p className="text-base leading-relaxed text-gray-700">
          Ready to revolutionize your fashion design career? 
          <a href="/designers" className="text-purple-600 hover:text-purple-800 transition duration-300 ease-in-out"> Get started with WearWonder today!</a>
        </p>
      </div>
    </div>
  );
}

export default HowItWorksPage;
