import React, { useState } from 'react';
import { ArrowRightIcon, MailIcon, PhoneIcon, LocationMarkerIcon } from '@heroicons/react/outline';

const ContactPage = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [responseType, setResponseType] = useState(''); // 'success' or 'error'

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Simulate a form submission
      console.log({ name, email, message });

      // Example of a successful submission
      setResponseMessage('Thank you for your message. We will get back to you soon.');
      setResponseType('success');

      // Reset the form fields
      setName('');
      setEmail('');
      setMessage('');
    } catch (error) {
      // Example of an error during submission
      setResponseMessage('Oops! Something went wrong. Please try again later.');
      setResponseType('error');
    }
  };

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <header className="text-center mb-12">
        <h1 className="text-4xl font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
          Contact <span className="text-[#7c3aed]">WearWonder</span>
        </h1>
        <p className="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
          Have questions or need assistance? We're here to help!
        </p>
      </header>

      <section className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-12">
        <div>
          <h2 className="text-3xl font-bold text-gray-900 mb-6">Get in Touch</h2>
          <form onSubmit={handleSubmit} className="space-y-6">
            <div>
              <label htmlFor="name" className="block font-medium text-gray-700 mb-2">
                Name
              </label>
              <input
                type="text"
                id="name"
                name="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-[#7c3aed] focus:ring-[#7c3aed] sm:text-sm p-3"
                required
              />
            </div>
            <div>
              <label htmlFor="email" className="block font-medium text-gray-700 mb-2">
                Email
              </label>
              <input
                type="email"
                id="email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-[#7c3aed] focus:ring-[#7c3aed] sm:text-sm p-3"
                required
              />
            </div>
            <div>
              <label htmlFor="message" className="block font-medium text-gray-700 mb-2">
                Message
              </label>
              <textarea
                id="message"
                name="message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                rows={4}
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-[#7c3aed] focus:ring-[#7c3aed] sm:text-sm p-3"
                required
              ></textarea>
            </div>
            <button
              type="submit"
              className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-white bg-[#7c3aed] hover:bg-[#6b2ec8]"
            >
              Send Message
              <ArrowRightIcon className="ml-2 -mr-1 h-5 w-5" aria-hidden="true" />
            </button>
          </form>
          {responseMessage && (
            <div
              className={`mt-6 p-4 rounded-md ${
                responseType === 'success'
                  ? 'bg-green-100 text-green-800'
                  : 'bg-red-100 text-red-800'
              }`}
            >
              {responseMessage}
            </div>
          )}
        </div>

        <div>
          <h2 className="text-3xl font-bold text-gray-900 mb-6">Contact Information</h2>
          <div className="space-y-6">
            <div className="flex items-start">
              <MailIcon className="h-6 w-6 text-[#7c3aed] mt-1 mr-4" />
              <div>
                <p className="font-medium text-gray-900">Email</p>
                <a href="mailto:support@wearwonder.com" className="text-[#7c3aed] hover:underline">
                  support@wearwonder.com
                </a>
              </div>
            </div>
            <div className="flex items-start">
              <PhoneIcon className="h-6 w-6 text-[#7c3aed] mt-1 mr-4" />
              <div>
                <p className="font-medium text-gray-900">Phone</p>
                <a href="tel:+1234567890" className="text-[#7c3aed] hover:underline">
                  +91-9711676564
                </a>
              </div>
            </div>
            <div className="flex items-start">
              <LocationMarkerIcon className="h-6 w-6 text-[#7c3aed] mt-1 mr-4" />
              <div>
                <p className="font-medium text-gray-900">Address</p>
                <p className="text-gray-500">#02, Diamond District, Domlur, Bengaluru</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-[#7c3aed] rounded-lg shadow-xl p-8 mb-16 text-center">
        <h2 className="text-3xl font-bold text-white mb-4">Ready to get started?</h2>
        <div className="flex flex-col sm:flex-row justify-center gap-4 mt-6">
          <a
            href="designers"
            className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-[#7c3aed] bg-white hover:bg-[#f0ebff]"
          >
            Bring your designs to reality
            <ArrowRightIcon className="ml-2 -mr-1 h-5 w-5" aria-hidden="true" />
          </a>
        </div>
      </section>
    </div>
  );
};

export default ContactPage;
