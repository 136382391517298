import React from 'react';
import { Link } from 'react-router-dom';

const LandingPage = () => {
  return (
    <main className="max-w-7xl mx-auto p-4 sm:p-6 lg:p-8">
      {/* Hero Section */}
      <section className="relative w-full h-[70vh] bg-cover bg-center rounded-xl overflow-hidden shadow-2xl" style={{ backgroundImage: 'url("https://images.pexels.com/photos/3984880/pexels-photo-3984880.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1")' }}>
        <div className="absolute inset-0 bg-gradient-to-r from-black to-transparent opacity-70"></div>
        <div className="relative flex flex-col items-start justify-center h-full text-left text-white p-8 sm:p-12 lg:p-16 max-w-2xl">
          <h2 className="text-4xl sm:text-5xl lg:text-6xl font-bold leading-tight mb-4">Empowering Designers with AI</h2>
          <p className="text-lg sm:text-xl lg:text-2xl mb-8 opacity-90">Transform your fashion sketches into production-ready catalogs, tech packs, and ready-to-purchase links.</p>
          <Link to="/designers">
            <button className="bg-indigo-600 hover:bg-indigo-700 text-white py-3 px-8 rounded-full font-semibold text-lg transition duration-300 ease-in-out transform hover:scale-105 shadow-lg">
              Join the Beta
            </button>
          </Link>
        </div>
      </section>

      {/* Features Section */}
      <section className="my-16 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
        {[
          { title: 'AI-Powered Design', description: 'Convert your sketches into high-quality, market-ready images and detailed tech packs with our AI tools.', image: 'https://images.pexels.com/photos/7147857/pexels-photo-7147857.jpeg?auto=compress&cs=tinysrgb&w=600' },
          { title: 'On-Demand Manufacturing', description: 'Access our network of 1000+ tailors and manufacturers to produce your designs without minimum order quantities.', image: 'https://images.pexels.com/photos/13159721/pexels-photo-13159721.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1' },
          { title: 'Direct-to-Consumer', description: 'Sell your unique creations directly to consumers, maintaining control and maximizing profitability.', image: 'https://images.pexels.com/photos/6207729/pexels-photo-6207729.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1' },
        ].map((feature, index) => (
          <div key={index} className="bg-white rounded-xl p-6 shadow-xl transition duration-300 ease-in-out transform hover:scale-105">
            <img src={feature.image} alt={feature.title} className="w-full h-48 object-cover rounded-lg mb-6" />
            <h3 className="text-2xl font-bold mb-3 text-gray-800">{feature.title}</h3>
            <p className="text-gray-600 leading-relaxed">{feature.description}</p>
          </div>
        ))}
      </section>

      {/* Community Section */}
      <section className="relative w-full h-96 bg-cover bg-center rounded-xl overflow-hidden shadow-2xl my-16" style={{ backgroundImage: 'url("https://images.pexels.com/photos/17710109/pexels-photo-17710109/free-photo-of-sewing-machines.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1")' }}>
        <div className="absolute inset-0 bg-gradient-to-b from-transparent to-black opacity-70"></div>
        <div className="relative flex flex-col items-center justify-center h-full text-center text-white p-8">
          <h2 className="text-4xl sm:text-5xl font-bold mb-6">Join Our Growing Community</h2>
          <p className="text-xl sm:text-2xl mb-8 max-w-2xl">500+ Fashion Designers | 1000+ Manufacturers | 20,000+ Weekly Production Capacity</p>
          <Link to="/designers">
            <button className="bg-pink-600 hover:bg-pink-700 text-white py-3 px-8 rounded-full font-semibold text-lg transition duration-300 ease-in-out transform hover:scale-105 shadow-lg">
              Start Creating Today
            </button>
          </Link>
        </div>
      </section>

      {/* Testimonials Section */}
<section className="my-16 bg-gradient-to-br from-indigo-50 to-pink-50 rounded-xl p-8 sm:p-12 shadow-xl">
  <h2 className="text-3xl sm:text-4xl font-bold mb-8 text-center text-gray-800">What Our Beta Users Are Saying</h2>
  <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
    {[
      { 
        quote: "WearWonder.com has revolutionized my design process. I can now go from sketch to production in record time!", 
        author: "Emily A.", 
        position: "Fashion Designer", 
        photo: "https://images.pexels.com/photos/4622409/pexels-photo-4622409.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" 
      },
      { 
        quote: "The AI-generated tech packs are incredibly accurate. It's like having a professional team at my fingertips.", 
        author: "Sophia M.", 
        position: "Emerging Designer", 
        photo: "https://images.pexels.com/photos/6461202/pexels-photo-6461202.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" 
      },
    ].map((testimonial, index) => (
      <div key={index} className="bg-white rounded-lg p-6 shadow-lg flex items-start space-x-4">
        <img src={testimonial.photo} alt={testimonial.author} className="w-16 h-16 rounded-full object-cover shadow-md" />
        <div className="flex-1">
          <p className="italic mb-4 text-gray-600 leading-relaxed">{testimonial.quote}</p>
          <p className="font-semibold text-gray-800">{testimonial.author}</p>
          <p className="text-sm text-gray-500">{testimonial.position}</p>
        </div>
      </div>
    ))}
  </div>
</section>


    </main>
  );
};

export default LandingPage;
