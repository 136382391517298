import React from 'react';

const TermsOfServicePage = () => {
  return (
    <div className="max-w-4xl mx-auto p-8 bg-gray-100 min-h-screen">
      <h1 className="text-4xl font-extrabold mb-8 text-center text-gray-900">Terms of Service</h1>
      <div className="bg-white shadow-lg rounded-lg p-8">
        <p className="mb-6 text-gray-700">Welcome to WearWonder. These Terms of Service ("Terms") govern your access to and use of our services, including our website and platform. By accessing or using our services, you agree to be bound by these Terms. Please read them carefully.</p>
        
        <h2 className="text-2xl font-semibold mb-4 text-gray-800">1. Use of Service</h2>
        <p className="mb-6 text-gray-700">WearWonder provides a platform for designers to create, manage, and sell fashion designs. You agree to use our services only for lawful purposes and in accordance with these Terms. You must not use the services in any way that could disable, overburden, or impair our servers or networks.</p>
        
        <h2 className="text-2xl font-semibold mb-4 text-gray-800">2. User Accounts</h2>
        <p className="mb-6 text-gray-700">To access certain features of our platform, you may need to create an account. You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account. You agree to notify us immediately of any unauthorized use of your account.</p>
        
        <h2 className="text-2xl font-semibold mb-4 text-gray-800">3. Content</h2>
        <p className="mb-6 text-gray-700">Users retain ownership of their content, including designs and other materials uploaded to WearWonder. By uploading content, you grant WearWonder a non-exclusive, royalty-free, worldwide license to use, display, and distribute your content for the purpose of operating and promoting our services.</p>
        
        <h2 className="text-2xl font-semibold mb-4 text-gray-800">4. Intellectual Property</h2>
        <p className="mb-6 text-gray-700">All intellectual property rights in the WearWonder platform and its content are owned by WearWonder or its licensors. You may not use, reproduce, or distribute any content from our platform without our express written permission.</p>
        
        <h2 className="text-2xl font-semibold mb-4 text-gray-800">5. Limitation of Liability</h2>
        <p className="mb-6 text-gray-700">WearWonder is not liable for any indirect, incidental, special, or consequential damages arising out of or in connection with your use of our services. Our liability to you for any claim arising from these Terms shall be limited to the amount paid by you for the use of our services.</p>
        
        <h2 className="text-2xl font-semibold mb-4 text-gray-800">6. Termination</h2>
        <p className="mb-6 text-gray-700">We reserve the right to terminate or suspend your account and access to our services at any time, without prior notice, for conduct that we believe violates these Terms or is harmful to other users or the platform.</p>
        
        <h2 className="text-2xl font-semibold mb-4 text-gray-800">7. Changes to Terms</h2>
        <p className="mb-6 text-gray-700">We may update these Terms from time to time. We will notify you of any changes by posting the new Terms on our website. Your continued use of our services after such changes constitutes your acceptance of the revised Terms.</p>
        
        <h2 className="text-2xl font-semibold mb-4 text-gray-800">8. Governing Law</h2>
        <p className="mb-6 text-gray-700">These Terms are governed by and construed in accordance with the laws of the jurisdiction in which WearWonder operates, without regard to its conflict of law principles. Any disputes arising out of or in connection with these Terms shall be resolved in the competent courts of that jurisdiction.</p>
        
        <h2 className="text-2xl font-semibold mb-4 text-gray-800">9. Contact Us</h2>
        <p className="text-gray-700">If you have any questions about these Terms, please contact us at <a href="mailto:support@wearwonder.com" className="text-indigo-600 hover:underline">support@wearwonder.com</a>.</p>
      </div>
    </div>
  );
};

export default TermsOfServicePage;
